<template>
  <b-container class="post-images-container">
    <gallery
      :images="showImages"
      :index="index"
      @close="index = null"
    />
    <b-row>
      <b-col class="post-images-col">
        <div v-if="!columns.length" class="text-center mt-3">There is no image to show.</div>
        <div
          v-else
          v-for="(c, j) in columns"
          :class="`column-${columns.length}`"
          :key="j"
        >
          <b-img
          class="post-img"
            v-if="c.image"
            :src="c.image"
            @click="openImage(c.id)"
          ></b-img>
          <div
            style="height: 100%"
            v-if="c.rows && c.rows.length"
          >
            <div
              v-for="(r, i) in c.rows"
              :key="i"
              :class="`row-${c.rows.length}`"
            >
              <div
                style="height: 100%"
                v-if="c.rows && r.columns.length"
              >
                <div
                  v-for="(rc, index) in r.columns"
                  :key="index"
                  :class="`row-column-${r.columns.length}`"
                >
                 <div class="more-img"
                     v-if="index===1 && i===1 && images.length > 5"
                     @click="openImage(rc.id)"
                  >
                  <span >+{{images.length - 5}}</span>
                  </div>
                  <b-img
                    v-if="rc.image"
                    :src="rc.image"
                    @click="openImage(rc.id)"
                  ></b-img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
import VueGallery from 'vue-gallery';
import { mapActions } from 'vuex';
import './css/PostImageCollage.css';

export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  name: 'postImageCollage',
  components: {
    gallery: VueGallery,
  },
  data() {
    return {
      index: null,
      activeClass: null,
      showImages: [],
    };
  },
  created() {
    this.showImages = this.images.map(imgObj => imgObj.image);
  },
  methods: {
    ...mapActions({
      eventGallery: 'events/fetchGallery',
      awardGallery: 'award/fetchGallery',
    }),
    // to do
    filterByEvent() {
      this.activeClass = 'event';
    },
    // to do
    filterByYear() {
      this.activeClass = 'year';
    },
    openImage(id) {
      this.index = id;
    },
  },
  watch: {
    images: {
      handler(info) {
        this.showImages = info.map(imgObj => imgObj.image);
      },
    },
  },
};
</script>


<style scoped>
.more-img{
    height: 100%;
    background-color: black;
    position: absolute;
    top: 0%;
    display: flex;
    font-size: 25px;
    color: white;
    width: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
}
.column-1 {
  width: 100%;
  height: 100%;
}

.column-2 {
  width: 50%;
  height: 100%;
}

.column-3:first-child {
  width: 50%;
}
.column-3 {
  width: 25%;
}
.container img {
  width: 100%!important;
  height: 100%!important;
  object-fit: cover;
  object-position: center;
}

.column-2 > .row-1 {
  width: 100%;
  height: 50%;
}
.column-2 .row-2 {
  width: 100%;
  height: 50%;
}
.row-column-2 {
  display: inline-block;
  width: 50%;
  height: 100%;
  position: relative;
}

.row-column-1 {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.post-images-col {
  display: flex;
  padding: 0 15px 0 15px;
  cursor: pointer;

}
.post-images-container {
  display: contents !important;
  font-family: Montserrat;
}
.post-images-container > p {
  font-family: Montserrat;
  font-size: 15px;
  color: #666;
}
.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.blueimp-gallery-display {
  display: none;
}
.post-img{
  max-height: 500px;
}
</style>
